<template>
  <div>
    <header class="header pa-2">
      <img src="@/assets/img/logo_fqm_branco.svg" alt="Logo" />
      <h1>{{ $route.meta.titulo.text }}</h1>
    </header>
    <v-container class="pa-4"><router-view/></v-container>
  </div>
</template>

<script>
export default {
  name: "HomeMedico",
  data: () => ({})
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #343a40;
  img {
    max-width: 100px;
  }
  h1 {
    font-size: 1.2rem;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
  }
}
</style>
